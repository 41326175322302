/* eslint-disable react/jsx-closing-tag-location */
import { useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { AreaChart, BarChart, LineChart } from '@mantine/charts';
import {
  ArrowUpIcon,
  ChatBubbleBottomCenterTextIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from '@heroicons/react/24/outline';

import { BetaComponent } from 'components/ui/BetaComponent';
import { ChartOne } from 'components/charts/ChartOne';
import { ChartThree } from 'components/charts/ChartThree';
import { HStack } from 'components/ui/HStack';
import { Page } from 'components/layout/Page/Page';
import { Card } from 'components/common/Card';
import {
  AdamInsights,
  AdamProcessedCard,
  DocumentProcessedBody,
} from 'components/dashboard';
import IntentCard from 'components/dashboard/IntentCard/IntentCard';
import { Inbox } from 'components/dashboard/Inbox';
import { OrderDrafts } from 'state/classes/OrderDrafts';
import { MessageIntents } from 'state/classes/MessageIntents';
import { genericErrorFeedback } from 'helpers/errors';
import { globalUser } from 'state/globalUser';

import google from 'assets/logo/google.svg';
import Whatsapp from 'assets/logo/whatsapp.svg';
import Phone from 'assets/logo/phone.svg';
import pdf from 'assets/document-icons/pdf.svg';
import excel from 'assets/document-icons/excel.svg';
import csv from 'assets/document-icons/csv.svg';
import phone2 from 'assets/document-icons/phone.svg';
import png from 'assets/document-icons/png.svg';
import adam from 'assets/adam-face.png';
import { MessageIntent } from 'models/Message';

interface Props {
  orderDrafts: OrderDrafts;
  messageIntents: MessageIntents;
  navigateToOrderDraftsProcessing: () => void;
}

const HomePage = observer(
  ({
    orderDrafts,
    messageIntents,
    navigateToOrderDraftsProcessing,
  }: Props) => {
    const isLoading = useMemo(
      () => orderDrafts.isLoading,
      [orderDrafts.isLoading],
    );

    const getOrderDrafts = useCallback(() => {
      // TODO: Remove and share the state internally in mobx
      orderDrafts.setUserId(globalUser.id);

      orderDrafts
        .loadOrderDraftsCount()
        .catch(genericErrorFeedback('Error loading order drafts'));
    }, [orderDrafts]);

    const getMessageIntents = useCallback(() => {
      messageIntents.load().catch(genericErrorFeedback('Error loading message intents'));
    }, [messageIntents]);

    const onProcessOrdersButtonClick = useCallback(() => {
      navigateToOrderDraftsProcessing();
    }, [navigateToOrderDraftsProcessing]);

    useEffect(() => {
      if (getOrderDrafts) getOrderDrafts();
    }, [getOrderDrafts]);

    useEffect(() => {
      if (getMessageIntents) getMessageIntents();
    }, [getMessageIntents]);

    return (
      <Page isLoading={isLoading}>
        <div className="relative flex-1 overflow-y-auto px-[10vw] pb-4">
          <div className="flex justify-between px-lg pt-10">
            <div className="flex flex-col justify-center gap-2">
              <h1 className="text-xl font-semibold leading-none text-gray-900">
                Dashboard
              </h1>
              <div className="flex items-center gap-2 text-sm font-medium text-gray-600">
                Your business at a glance
              </div>
            </div>
          </div>
          <HStack>
            <div className="h-full w-full px-lg">
              <div className="grid grid-cols-12 gap-4 pt-5">
                <Inbox
                  className="col-span-4 4xl:col-span-3 row-span-2"
                  orderCount={messageIntents.unreadCounts[MessageIntent.ORDER]}
                  otherCount={messageIntents.unreadCounts[MessageIntent.OTHER]}
                />

                <IntentCard
                  cardTitle="Unprocessed orders"
                  cardSubtitle="Track and manage your orders"
                  intentProps={[
                    {
                      cardTitle: (
                        <p className="font-medium">
                          <span className="text-base font-semibold">
                            {orderDrafts.count}
                          </span>
                          {' '}
                          Order drafts
                        </p>
                      ),
                      cardSubtitles: [
                        `${orderDrafts.count} Emails`,
                        '0 Whatsapp',
                        '0 Phone calls',
                        '0 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'blue.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: onProcessOrdersButtonClick,
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />

                <IntentCard
                  cardTitle="Unprocessed supplier notes"
                  cardSubtitle="Track and manage your supplier notes"
                  intentProps={[
                    {
                      cardTitle: (
                        <p className="font-medium">
                          <span className="text-base font-semibold">20</span>
                          {' '}
                          Supplier notes
                        </p>
                      ),
                      cardSubtitles: [
                        '14 Emails',
                        '0 Whatsapp',
                        '0 Phone calls',
                        '6 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 30, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'green.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />

                <IntentCard
                  cardTitle="Q&A"
                  cardSubtitle="Track and manage your Q&A"
                  intentProps={[
                    {
                      cardTitle: 'Product stock levels',
                      cardSubtitles: [
                        '10 Emails',
                        '0 Whatsapp',
                        '22 Phone calls',
                        '0 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 100, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                    {
                      cardTitle: 'Undelivered orders',
                      cardSubtitles: [
                        '10 Emails',
                        '12 Whatsapp',
                        '0 Phone calls',
                        '5 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 20, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                    {
                      cardTitle: 'Quantity of the product delivered',
                      cardSubtitles: [
                        '17 Emails',
                        '2 Whatsapp',
                        '5 Phone calls',
                        '2 SMS',
                      ],
                      cardData: [
                        { name: 'Unresolved', value: 200, color: 'gray.2' },
                        { name: 'Resolved', value: 400, color: 'red.5' },
                      ],
                      buttons: [
                        {
                          title: 'Process',
                          theme: 'primary',
                          onClick: () => {},
                        },
                      ],
                    },
                  ]}
                  className="col-span-4 4xl:col-span-3"
                />
              </div>

              <div className="mb-3 mt-6 flex w-full items-center space-x-2">
                <p className="text-sm font-semibold text-gray-800">Analytics</p>
                {/* <div className="flex-1 h-0.5 bg-gray-300" /> */}
              </div>

              <div className="grid grid-cols-12 gap-4">
                <Card className="col-span-8 row-start-1">
                  <Card.Body>
                    <h3 className="card-title mb-4 flex flex-col items-start">
                      <span className="text-sm font-semibold text-gray-800">
                        Processing time per order
                      </span>
                      <span className="mt-1 text-xs text-gray-500">
                        Average time to process an order and number of orders processed
                      </span>
                    </h3>
                    <LineChart
                      h={200}
                      data={[
                        {
                          date: 'Jan',
                          processTime: 15,
                          orderAmount: 2100,
                        },
                        {
                          date: 'Feb',
                          processTime: 13.5,
                          orderAmount: 2500,
                        },
                        {
                          date: 'Mar',
                          processTime: 12,
                          orderAmount: 2300,
                        },
                        {
                          date: 'Apr',
                          processTime: 11,
                          orderAmount: 3500,
                        },
                        {
                          date: 'May',
                          processTime: 9,
                          orderAmount: 2400,
                        },
                        {
                          date: 'June',
                          processTime: 8.9,
                          orderAmount: 3400,
                        },
                        {
                          date: 'July',
                          processTime: 7,
                          orderAmount: 4600,
                        },
                        {
                          date: 'Aug',
                          processTime: 5,
                          orderAmount: 4700,
                        },
                      ]}
                      dataKey="date"
                      series={[
                        { name: 'processTime', color: 'indigo.5', label: 'Processing time (min)' },
                        {
                          name: 'orderAmount', color: 'indigo.3', label: 'Orders', yAxisId: 'right',
                        },
                      ]}
                      yAxisProps={{
                        domain: [5, 15],
                      }}
                      rightYAxisProps={{
                        domain: [2000, 5000],
                        ticks: [2000, 3000, 4000, 5000],
                        tickFormatter(value) {
                          if (value > 1000) {
                            return `${value / 1000}k`;
                          }
                          return value;
                        },
                      }}
                      withLegend
                      withRightYAxis
                    />
                  </Card.Body>
                </Card>

                <AdamInsights />

                <div className="col-span-3 col-start-1 row-span-1 grid grid-cols-2 grid-rows-2 gap-lg hidden">
                  <AdamProcessedCard
                    icon={google}
                    title="Emails processed by Adam"
                    count={2000}
                  />
                  <AdamProcessedCard
                    icon={Whatsapp}
                    title="Amazing mates"
                    count={2000}
                  />
                  <AdamProcessedCard
                    icon={Phone}
                    title="Amazing mates"
                    count={2000}
                  />
                  {/* <AdamProcessedCard
                    icon={Phone}
                    title="Amazing mates"
                    count={2000}
                  /> */}
                </div>

                <Card className="col-span-3 row-span-1 hidden">
                  <Card.Body>
                    <div className="flex flex-col justify-between px-0 pb-0">
                      <div className="mb-4">
                        <div className="mb-1 flex items-center">
                          <span className="me-2 text-xl font-bold leading-[1] text-gray-800">
                            100
                          </span>
                          <span className="flex items-center space-x-0.5 rounded-sm bg-success-50 p-0.5 text-xs text-success-500">
                            <ArrowUpIcon className="h-3 w-3 text-success-500" />
                            <p>2.2%</p>
                          </span>
                        </div>
                        <span className="text-xs font-semibold text-gray-500">
                          Total processed orders
                        </span>
                      </div>
                      <div>
                        <LineChart
                          h={140}
                          data={[
                            {
                              date: 'Mar 22',
                              Apples: 100,
                            },
                            {
                              date: 'Mar 23',
                              Apples: 120,
                            },
                            {
                              date: 'Mar 24',
                              Apples: 100,
                            },
                            {
                              date: 'Mar 26',
                              Apples: 90,
                            },
                            {
                              date: 'Mar 28',
                              Apples: 180,
                            },
                            {
                              date: 'Mar 30',
                              Apples: 120,
                            },
                            {
                              date: 'Jun 2',
                              Apples: 140,
                            },
                            {
                              date: 'Jun 3',
                              Apples: 170,
                            },
                          ]}
                          dataKey="date"
                          series={[{ name: 'Apples', color: 'black' }]}
                          curveType="natural"
                          withYAxis={false}
                          withXAxis={false}
                          dotProps={{
                            className: 'hidden hover:block',
                          }}
                          yAxisProps={{
                            domain: [80, 170],
                            ticks: [],
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <Card className="col-span-3 row-span-1 hidden">
                  <Card.Body>
                    <div className="flex flex-col justify-between px-0 pb-0">
                      <div className="mb-4">
                        <div className="mb-1 flex items-center">
                          <span className="me-2 text-xl font-bold leading-[1] text-gray-800">
                            100
                          </span>
                          <span className="flex items-center space-x-0.5 rounded-sm bg-success-50 p-0.5 text-xs text-success-500">
                            <ArrowUpIcon className="h-3 w-3 text-success-500" />
                            <p>2.2%</p>
                          </span>
                        </div>
                        <span className="text-xs font-semibold text-gray-500">
                          Total processed orders
                        </span>
                      </div>
                      <div>
                        <LineChart
                          h={140}
                          data={[
                            {
                              date: 'Mar 22',
                              Apples: 100,
                            },
                            {
                              date: 'Mar 23',
                              Apples: 120,
                            },
                            {
                              date: 'Mar 24',
                              Apples: 100,
                            },
                            {
                              date: 'Mar 26',
                              Apples: 90,
                            },
                            {
                              date: 'Mar 28',
                              Apples: 180,
                            },
                            {
                              date: 'Mar 30',
                              Apples: 120,
                            },
                            {
                              date: 'Jun 2',
                              Apples: 140,
                            },
                            {
                              date: 'Jun 3',
                              Apples: 170,
                            },
                          ]}
                          dataKey="date"
                          series={[{ name: 'Apples', color: 'black' }]}
                          curveType="natural"
                          withYAxis={false}
                          withXAxis={false}
                          dotProps={{
                            className: 'hidden hover:block',
                          }}
                          yAxisProps={{
                            domain: [80, 170],
                            ticks: [],
                          }}
                        />
                      </div>
                    </div>
                  </Card.Body>
                </Card>

                <div className="hidden">
                  <Card className="col-span-8 row-start-2">
                    <Card.Body>
                      <h3 className="card-title mb-4 flex flex-col items-start">
                        <span className="text-sm font-semibold text-gray-800">
                          Process time per product
                        </span>
                        <span className="mt-1 text-xs text-gray-500">
                          Average time to process a product
                        </span>
                      </h3>
                      <AreaChart
                        h={200}
                        data={[
                          {
                            date: 'Mar 22',
                            Apples: 2,
                          },
                          {
                            date: 'Mar 23',
                            Apples: 1.8,
                          },
                          {
                            date: 'Mar 24',
                            Apples: 1.6,
                          },
                          {
                            date: 'Mar 25',
                            Apples: 1.4,
                          },
                          {
                            date: 'Mar 26',
                            Apples: 1,
                          },
                        ]}
                        dataKey="date"
                        series={[{ name: 'Apples', color: 'indigo.6' }]}
                      />
                    </Card.Body>
                  </Card>

                  <Card className="col-span-3 row-span-1 hidden">
                    <Card.Body>
                      <div className="flex flex-col justify-between px-0 pb-0">
                        <div className="">
                          <div className="mb-6 flex items-center">
                            <span className="me-2 text-xl font-bold leading-[1] text-gray-800">
                              Top selling products
                            </span>
                          </div>
                        </div>
                        <BarChart
                          h={300}
                          data={[
                            {
                              product: 'Apple',
                              Apple: 100,
                            },
                            {
                              product: 'Pineapple',
                              Pineapple: 80,
                            },
                            {
                              product: 'Banana',
                              Banana: 60,
                            },
                            {
                              product: 'Orange',
                              Orange: 50,
                            },
                            {
                              product: 'Cherry',
                              Cherry: 40,
                            },
                            {
                              product: 'Lemon',
                              Lemon: 30,
                            },
                          // {
                          //   product: 'Lime',
                          //   Lime: 20,
                          // },
                          // {
                          //   product: 'Grapes',
                          //   Grapes: 10,
                          // },
                          ]}
                          dataKey="product"
                          series={[
                            { name: 'Apple', color: 'red.5' },
                            { name: 'Pineapple', color: 'yellow.5' },
                            { name: 'Banana', color: 'green.5' },
                            { name: 'Orange', color: 'orange.5' },
                            { name: 'Cherry', color: 'red.5' },
                            { name: 'Lemon', color: 'blue.5' },
                          ]}
                          type="stacked"
                          orientation="vertical"
                        />
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="col-span-3 col-start-10 row-span-3 row-start-1 hidden">
                    <Card.Body className="block space-y-4">
                      <div className="space-y-4 border-b border-dashed pb-4">
                        <div className="flex items-center">
                          <div className="symbol symbol-50px me-3">
                            <img
                              src={adam}
                              className="h-10 w-10 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-hover-primary fw-bold text-sm text-gray-800">
                              Adam
                            </p>
                            <span className="fw-semibold block text-xs text-gray-500">
                              Yestarday at 5:06 PM
                            </span>
                          </div>
                        </div>
                        <p>Hello I&apos;m under the water please help me</p>
                        <div className="flex w-full justify-between">
                          <div className="flex space-x-4">
                            <HandThumbUpIcon className="h-5 w-5" />
                            <HandThumbDownIcon className="h-5 w-5" />
                          </div>
                          <div>
                            <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
                          </div>
                        </div>
                      </div>
                      <div className="space-y-4 border-b border-dashed pb-4">
                        <div className="flex items-center">
                          <div className="symbol symbol-50px me-3">
                            <img
                              src={adam}
                              className="h-10 w-10 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="flex-grow-1">
                            <p className="text-hover-primary fw-bold text-sm text-gray-800">
                              Adam
                            </p>
                            <span className="fw-semibold block text-xs text-gray-500">
                              Yestarday at 5:06 PM
                            </span>
                          </div>
                        </div>
                        <p>Hello I&apos;m under the water please help me</p>
                        <div className="flex w-full justify-between">
                          <div className="flex space-x-4">
                            <HandThumbUpIcon className="h-5 w-5" />
                            <HandThumbDownIcon className="h-5 w-5" />
                          </div>
                          <div>
                            <ChatBubbleBottomCenterTextIcon className="h-5 w-5" />
                          </div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="col-span-2 row-span-2 row-start-4">
                    <Card.Body>
                      <h3 className="card-title flex flex-col items-start">
                        <span className="text-sm font-semibold text-gray-800">
                          Processed order formats
                        </span>
                        <span className="mt-1 text-xs text-gray-500">
                          20 countries share 97% visits
                        </span>
                      </h3>
                      <div>
                        <DocumentProcessedBody
                          icon={pdf}
                          title="PDF"
                          value={9000}
                        />
                        <DocumentProcessedBody
                          icon={excel}
                          title="Excel"
                          value={9000}
                        />
                        <DocumentProcessedBody
                          icon={csv}
                          title="CSV"
                          value={9000}
                        />
                        <DocumentProcessedBody
                          icon={phone2}
                          title="Phone"
                          value={9000}
                        />
                        <DocumentProcessedBody
                          icon={png}
                          title="Text"
                          value={9000}
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>

              <BetaComponent>
                <div className="border-light-border-primary mt-15 flex flex-col gap-smd border-t border-solid pt-1">
                  {/* <div className="text-sm text-gray-500 mt-1">Analytics</div> */}
                  <div className="mt-8 grid grid-cols-12 gap-lg overflow-hidden">
                    <ChartOne />
                    <ChartThree />
                  </div>
                </div>
              </BetaComponent>
            </div>
          </HStack>
        </div>
      </Page>
    );
  },
);

export default HomePage;
